/*eslint-disable */
// import Spcm_milestonesModel from '../../Model/Spcm_milestones'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let spcmMilestones = {
  /**
   * spcm_milestonesList
   */
  async spcm_milestonesList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_milestones_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcm_milestonesList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * spcm_milestonesView
   */
  async spcm_milestonesView (context, spcmMilestonesId) {
    try {      
      let post_data = new FormData();
      post_data.append('ms_id', spcmMilestonesId);
      return await request.curl(context, "spcm_milestones_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at spcm_milestonesView() and Exception:",err.message)
    }    
  },

  /**
   * spcm_milestonesAdd
   */
  async spcm_milestonesAdd (context, spcm_milestonesObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcm_milestonesObj) {
        post_data.append(key, spcm_milestonesObj[key]);
      }

      return await request.curl(context, "spcm_milestones_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcm_milestonesAdd() and Exception:',err.message)
    }
  },

  /**
   * spcm_milestonesEdit
   */
  async spcm_milestonesEdit (context, spcm_milestonesObj) {
    try{
      let post_data = new FormData();
    
      for (let key in spcm_milestonesObj) {
        post_data.append(key, spcm_milestonesObj[key]);
      }

      return await request.curl(context, "spcm_milestones_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at spcm_milestonesEdit() and Exception:',err.message)
    }
  },

  /**
   * spcm_milestonesDelete
   */
  async spcm_milestonesDelete (context, spcmMilestonesId) {
    try{
      let post_data = new FormData();
      
      post_data.append('ms_id', spcmMilestonesId);

      return await request.curl(context, "spcm_milestones_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcm_milestonesDelete() and Exception:',err.message)
    }
  },

  /**
   * spcm_studentMilestoneList
   * List all the milestones depending on the student's class, strategy, university_partnership_type, month
   */
  async spcm_studentMilestoneList (context, studentMilestoneObj) {
    try{
      let post_data = new FormData();
      
      for (let key in studentMilestoneObj) {
        if (studentMilestoneObj[key]) {
          post_data.append(key, studentMilestoneObj[key]);
        }
        else if (typeof studentMilestoneObj[key] == "number") {
          post_data.append(key, studentMilestoneObj[key]);
        }
      }

      return await request.curl(context, "spcm_student_milestone_list", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcm_studentMilestoneList() and Exception:',err.message)
    }
  },

  /**
   * spcm_distinctMilestoneNumberList
   * List the distinct milestone numbers that will be used to create milestone number filters
   */
  async spcm_distinctMilestoneNumberList (context, studentMilestoneObj) {
    try{
      let post_data = new FormData();

      for (let key in studentMilestoneObj) {
        if (studentMilestoneObj[key]) {
          post_data.append(key, studentMilestoneObj[key]);
        }
        else if (typeof studentMilestoneObj[key] == "number") {
          post_data.append(key, studentMilestoneObj[key]);
        }
      }

      return await request.curl(context, "spcm_distinct_milestone_number_list", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at spcm_distinctMilestoneNumberList() and Exception:',err.message)
    }
  },
  
  /**
   * spcm
  */
  async spcm_CountryStrategyList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "spcm_country_strategy_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at spcmCalendarList() and Exception:',err.message)
    }
  }
}

export {
  spcmMilestones
}
